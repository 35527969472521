module.exports = {
  //-- SITE SETTINGS -----
  author: "@李仁冲",
  siteTitle: "李仁冲的博客「万能的冲叔」",
  siteShortTitle: "万能的冲叔", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "Hi there! 我是李仁冲，一位竞争性情报信息分析与健康行为改变专家",
  siteUrl: "https://konstantin.digital",
  siteLanguage: "zh-cn",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "李仁冲", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: true, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "G-4JTHW4HR", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#000000",
      secondary: "#CDF3E1",
      tertiary: "#F2F2F2",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "#2A2926",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "Roboto, Arial, sans-serif",
  },

  //-- ARTICLES SECTION SETTINGS -----
  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://blog.lirenchong.com/index.json",
  shownArticles: 3,

  //-- SOCIAL MEDIA SETTINGS -----
  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance
  socialMedia: [

    {
      name: "🏠 Blog",
      url: "https://blog.lirenchong.com",
    },
    {
      name: "公众号",
      url: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5NjM4MjcyMQ==&scene=124#wechat_redirect",
    },
    {
      name: "🎶 抖音",
      url: "https://v.douyin.com/LvnTKx4/",
    },
    {
      name: "微博",
      url: "https://weibo.com/lirenchong",
    },
    {
      name: "Github",
      url: "https://github.com/lirenchong",
    },
    {
      name: "Mail",
      url: "mailto:lirenchong@gmail.com",
    },
    // {
    //     name: "Behance",
    //     url: "https://www.behance.net/konstanmnster"
    // },
  ],

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "Blog博客全集",
        url: "https://blog.lirenchong.com",
      },
      {
        name: "新文章",
        url: "/#articles",
      },
      {
        name: "作品集",
        url: "/#projects",
      },
      {
        name: "了解冲叔",
        url: "/#about",
      },
    ],
    button: {
      useFileName: false,
      name: "联系我",
      fileName: "", // the file has to be placed inside the static folder at the root level
      url: "/#contact", // if useFileName=false, you can set an anchor link here and use the button for navigational purposes
    },
  },
  footerLinks: [
    {
      name: "隐私",
      url: "/privacy",
    },
    {
      name: "印记",
      url: "/imprint",
    },
  ],
}
